import React from "react";
import { string, func, bool, any } from "prop-types";
import { Input } from "antd";
import { labelStyles, errorStyles } from "./form";

const { TextArea } = Input;

const Step3 = ({ additionalInfo, setAdditionalInfo, showErrors, errors }) => {
  return (
    <div className="relative w-full">
      <div>
        <div className={labelStyles}>
          In a few sentences, tell about your referred business and why you
          think they’d benefit from joining SpotOn. Don’t be afraid to get
          specific. Tell us about your first impressions, the products they’d
          use, the personality of the owner, or any other information that would
          make it easier to talk with them in the future.
        </div>
        <TextArea
          rows={6}
          value={additionalInfo}
          onChange={({ target: { value } }) => setAdditionalInfo(value)}
          style={{
            borderColor: showErrors && errors.additionalInfo ? "#F73E3C" : "",
          }}
        />
        <div
          className={`${errorStyles} opacity-${
            showErrors && errors.additionalInfo ? 100 : 0
          }`}
        >
          Please write a bit more about the potential deal
        </div>
      </div>
    </div>
  );
};

Step3.propTypes = {
  additionalInfo: string.isRequired,
  setAdditionalInfo: func.isRequired,
  showErrors: bool.isRequired,
  errors: any.isRequired,
};

export default Step3;
