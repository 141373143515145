import React from "react";
import { string, func, any, bool } from "prop-types";
import { Input } from "antd";
import { labelStyles, stepStyles, errorStyles } from "./form";

const Step1 = ({ repEmail, setRepEmail, showErrors }) => {
  return (
    <div className="max-w-[575px] mx-auto">
      <div>
        <div className={labelStyles}>SpotOn Email Address</div>
        <Input
          value={repEmail}
          onChange={({ target: { value } }) => setRepEmail(value)}
          placeholder="E.g. johndoe@spoton.com"
          size="large"
          style={{
            borderColor: showErrors ? "#F73E3C" : "",
          }}
        />
        <div className={`${errorStyles} opacity-${showErrors ? 100 : 0}`}>
          Oops! Looks like something went wrong. Please make sure you’re using a
          spoton.com email. If that email is correct and you’re still receiving
          this message, you might not be eligible to participate in the program.
          Contact your direct supervisor to confirm eligibility.
        </div>
      </div>
    </div>
  );
};

Step1.propTypes = {
  repEmail: string.isRequired,
  setRepEmail: func.isRequired,
  showErrors: bool.isRequired,
  errors: any.isRequired,
};

export default Step1;
